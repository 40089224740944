import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
 callApi:boolean,
 apiCallNum:number, 
 uTripApiCallNum:number, 
 
}
const initialState: IssueInitialState = {
 callApi:false,
 apiCallNum:0,
 uTripApiCallNum:0
}
const CallApiSlice = createSlice({
  name: 'CallApi',
  initialState,
  reducers: {
    setApiCall(state, action: PayloadAction<boolean>) {
      state.callApi= action.payload
    },
    setApiCallNum(state, action: PayloadAction<number>) {
      state.apiCallNum= action.payload
    },
    setUTripApiCallNum(state, action: PayloadAction<number>) {
      state.uTripApiCallNum= action.payload
    },
  },
})

export default CallApiSlice.reducer

export const {setApiCall,setApiCallNum,setUTripApiCallNum} = CallApiSlice.actions
