import {useState,useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'

import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Please enter valid Email Address").required('Email is required'),
})

export function ForgotPassword() {
  const navigate=useNavigate();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [errorMessage,setErrorMessage]  = useState('')

  const [showSuccessMessage,setShowSuccessMessage]=useState("")
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setLoading(true)
      ForgetPassword()
     
    },
  })


  useEffect(() => {
    const authToken = localStorage.getItem('token')
        const nauthToken = authToken?.replaceAll(/"/g, '')
        if (nauthToken) {
          navigate('/schedule/personal_schedule')
        }
  }, [])


  const ForgetPassword = async () => {
    let data = {
      email: formik.values.email,
    }

    let formData = new FormData()

    formData.append('email', data.email)


    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v2/forgot-password`,
        formData
      )

      if (response.data.status === true) {
        setHasErrors(false)
      }
      localStorage.setItem("verification-email",formik.values.email)
      setShowSuccessMessage(response.data.message)
      
      setTimeout(() => {
        navigate("/emailverification");
      }, 3000);
    } catch (error: any) {
      setErrorMessage(error.response.data.message)
      setHasErrors(true)
      if (error.response) {
        const errorResponse = error.response.data

        console.error('Error response:', errorResponse)

        if (errorResponse.errors) {
          console.error('Validation errors:', errorResponse.errors)
        }

        console.error('Status code:', error.response.status)
        setLoading(false)
        
      } else if (error.request) {
        console.error('No response received:', error.request)
      } else {
        console.error('Error setting up the request:', error.message)
        setLoading(false)
      }
    }

    

   
  }

  let styleForFormIcon = {
    maindiv: {
      position: 'relative',
      display: 'flex',
    },
    icon: {
      position: 'absolute',
      top: '12px',

      color: '#4b00ff',
      height: '24px',
      width: '24px',
      display: 'flex',
      marginRight: '50px',
    },
  }
  const styleForInput = {
    background: 'transparent',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    paddingLeft: '50px',
    color: 'rgba(189, 189, 189, 1)',
    /* width: "458px", */
    height: '48px',
    borderRadius: '8px',
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center '>
        {/* begin::Title */}
        <h2 className='text-white' style={{fontSize: '24px', fontWeight: '600',marginBottom:"48px"}}>
          Forgot Password
        </h2>

        <div
          className='text-white '
          style={{fontSize: '16px', fontWeight: '400',color:"rgba(255, 255, 255, 1)"}}
        >
          Enter the email associated with your account and we will send an email with instructions
          to recover your password.
        </div>
      </div>

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold' style={{color:'red'}}>
            {/* Sorry, looks like there are some errors detected, please try again. */}
            {errorMessage}
          </div>
        </div>
      )}



      {hasErrors === false && (
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-success font-weight-bold'>
          {showSuccessMessage}
          </div>
        </div>
      )}
    
      <div className='fv-row ' style={{marginTop: '80px'}}>
        <div className='fv-row '>
          <div
            className='col-12 form-input'
            style={{...(styleForFormIcon.maindiv as React.CSSProperties)}}
          >
            <input
              type='email'
              id='email'
              name='email'
              className='form-control'
              style={{...styleForInput}}
              placeholder='Email*'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              autoComplete='off'
            />

            <img
              src={toAbsoluteUrl('/media/Home/email.png')}
              alt=''
              style={{...(styleForFormIcon.icon as React.CSSProperties), marginLeft: '10px'}}
            />
          </div>
        </div>

        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
    
      <div className='d-grid ' style={{marginTop: '80px'}}>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn  me-0'
          style={{background: 'rgba(229, 9, 20, 1)', fontSize: '16px', fontWeight: '600'}}
        >
          {!loading && (
            <span
              className='indicator-label'
              style={{color: 'white'}}
            >
              Sent Instructions
            </span>
          )}

          {loading && (
            <span className='indicator-progress' style={{display: 'block', color: 'white'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
    
      </div>
     
    </form>
  )
}
