import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Modal, Button} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import axios from 'axios'
import {setItem} from '../../../../store/slices/EventHistoryDropDownSlice'
import {setItem as setFriendModuleItem} from '../../../../store/slices/FriendDropDownSlice'
import {
  setApiResponse,
  setIds,
  setIsDeleted,
  setIsUpdated,
  setOpenPinId,
  setShowPopUpModalForpin,
} from '../../../../store/slices/UPadNotesIdSlice'
import {
  setEmptyState,

  setIsLocationExist,
  setIshareViaTextAllowed,
  setApiResponse as setResponse,
  setSendToInAppFriend,
  setShowPopUpForDeepLink,
  setShowResponseAfterLeave,
  setShowShareButton,
} from '../../../../store/slices/Ushare/ShareScheduleSlices/ShareYourScheduleDataslice'
import {MenuComponent} from '../../../../_metronic/assets/ts/components'
import {SetCardId} from '../../../../store/slices/UPadNotesIdSlice'
import {setCreateForInvitedUser, setIsAddToInvited, setIsAddToPersonal, setIsAddToShared, setShowInvitationPopup, showDeleteEventModal} from '../../../../store/slices/CreateEventDataSlice'
import {setMessageModalState} from '../../../../store/slices/MessageModalSlice'
import {setAction} from '../../../../store/slices/ResponseHandlingSlice'

import {setApiResponse as setApiResponseFromResponseHandling} from '../../../../store/slices/ResponseHandlingSlice'
import {setReduxShowModal, setShowDeleteMessageModalForFriends, setShowMessageModalForUshare, setShowUserManagementModal} from '../../../../store/slices/ShowModalSlice'
import {
  setEevntDetailId,
  setEventDetailPage,
  setStatesEmpty,
} from '../../../../store/slices/Ushare/ScanFriends/ScanFriendModalSlice'
import { RootState } from '../../../../store'
import { setNoteUpdateState, setpopUpForUmapSelectEvent } from '../../../../store/slices/SelectEventIdSlice'
import { emptySelectedUserData, setIsActionAllowed, setIsCompanyInvitation, setIshowInvited, setIshowMySharedSchedule, setShowResponseLeaveSchedule } from '../../../../store/slices/User_management/UserManagementSlice'
import { setCallApi, setCardItem } from '../../../../store/slices/HomePageCardDropDown'
import { setCharacterLimitExceedPopUp, setChatLimitExceed, setIsGroupCreate, setLimitExceedPopup, setLimitExceedTitle } from '../../../../store/slices/Group_chat/GroupChatSlice'
import { setIsInvitedUser } from '../../../../store/slices/EditEventDataSlice'
import { setAddToSharedType } from '../../../../store/slices/AddToScheduleSlice'
import { setPopForAccountActivation, setPopForResendEmail } from '../../../../store/slices/Settings/Account/AuthSlice'

import "../../../../App.css"
import { setShowMessageForEditFriend } from '../../../../store/slices/Ushare/Friends/SelectFriend'
interface MyComponentProps {
  show: boolean
  message: string
  imgPath: string
  title: string
  response?: boolean
  returnPath: string
}
const MessageModal: React.FC<MyComponentProps> = ({show, message, imgPath, title, returnPath}) => {
  let location = useLocation()
  let dispatch = useDispatch()
  let navigate = useNavigate()
  const [showModal, setShowModal] = useState(true)

  /* let changeMessageMOdalState=()={
    dispatch(setMessageModalState(false))
  } */

  const isShow = useSelector((state: RootState) => {
    return state.MessageModalSlice.isShow
  })
  let ResponseHandling=useSelector((state:RootState)=>{
    return state.ResponseHandlingSlice

  })
  
  let userManagementData=useSelector((state:RootState)=>{
    return state.UserManagementSlice
  })
  let homePageCard=useSelector((state:RootState)=>{
    return state.HomePageCardDropDown
  })
  let createEeventData=useSelector((state:RootState)=>{
    return state.CreateEventDataSlice
  })
  const checkSelectOption = useSelector((state: RootState) => {
    return state.AddToScheduleData
  })
  const changeMessageModalState = () => {
    dispatch(setMessageModalState({isShow: false, status: false}))
    dispatch(setAction(false))
    dispatch(setApiResponseFromResponseHandling({apiStatus: false, apiMessage: ''}))
    dispatch(setReduxShowModal({showModal: false}))
    //Share scheudle state
    // dispatch(setEmptyState())
    //reset states of scan qr
    dispatch(setStatesEmpty())
    dispatch(setSendToInAppFriend(false))
   //group chat
   dispatch(setLimitExceedTitle(""))
    dispatch(setLimitExceedPopup(false))
    dispatch(setIsGroupCreate(false))

    dispatch(setIsLocationExist(false))

    dispatch(setIshareViaTextAllowed(false))
    dispatch(setShowPopUpForDeepLink(false))
    //use for show pop modal in umap if user delete pin point with attched event 
    dispatch(setShowPopUpModalForpin(false))

    dispatch(setPopForResendEmail(false))
    dispatch(setPopForAccountActivation(false))

    if (returnPath ==="/signin") {
    

      navigate('/signin')
    }
   else if(returnPath ==="/user_administration/manage_user"){

      dispatch(setShowUserManagementModal(false))

      navigate("/user_administration/manage_user");
      return
    }else if(returnPath.includes('/ushare/friends/')){
      dispatch(setEevntDetailId(0))
      dispatch(setEventDetailPage(false))
      navigate(returnPath)
      return
    }
    else if(returnPath ===  "/subscriptions_plan"){

      dispatch(setShowMessageForEditFriend(true))
      navigate("/subscriptions_plan");
      return
    }
   else if (returnPath === '/ushare/share_your_schedule/locatedEvent/detail') {
      dispatch(setEevntDetailId(0))
      dispatch(setEventDetailPage(false))
      return
    }
   else if (location.pathname === '/upad') {
      dispatch(setIsDeleted(false))
      dispatch(setIsUpdated(true))
      dispatch(setApiResponse({apiStatus: false, apiMessage: ''}))

      navigate('/upad')
    } else if (location.pathname === '/ushare/share_your_schedule') {
      dispatch(setApiResponse({apiStatus: false, apiMessage: ''}))
      dispatch(setResponse({apiStatus: false, apiMessage: ''}))
      dispatch(setSendToInAppFriend(false))
      dispatch(setEmptyState())
      //reset states of scan qr
      dispatch(setStatesEmpty())
      dispatch(setShowShareButton(false))
      navigate('/ushare/share_your_schedule')
    } else if (returnPath === "/Signup") {
      localStorage.clear()
      navigate("/Signup");
      return
    } else if(returnPath === "same_screen"){
      dispatch(setShowResponseLeaveSchedule(false))
      dispatch(setShowResponseAfterLeave(false))
      dispatch(setShowInvitationPopup(false))
      dispatch(setNoteUpdateState(false))
      dispatch(setSendToInAppFriend(false))
      dispatch(setCharacterLimitExceedPopUp(false))
      dispatch(setpopUpForUmapSelectEvent(false))
      return

    }else if(returnPath === "/group_messages") {
      dispatch(setCharacterLimitExceedPopUp(false))
      dispatch(setChatLimitExceed(false))
      navigate("/group_messages");
    }
    else if(returnPath === "/same_path") {
      dispatch(setFriendModuleItem(''))
      dispatch(setCardItem(''))
      dispatch(setShowMessageModalForUshare(false))
      dispatch(setReduxShowModal({showModal:false}))
      dispatch(setShowDeleteMessageModalForFriends(false))
      dispatch(setCharacterLimitExceedPopUp(false))
      if (location.pathname === '/schedule/personal_schedule') {
        dispatch(
          setCallApi({
            screen: 'personal_schedule',
            random_number: Math.floor(Math.random() * 999999),
          })
        )
      }
    /*   if (location.pathname === '/schedule/manage_shared_schedule') {
        dispatch(
          setCallApi({screen: 'share_schedule', random_number: Math.floor(Math.random() * 999999)})
        )
      } */
      navigate(location.pathname);
      
    }else if(location.pathname === "/schedule/create_event"){

      //navigate(ResponseHandling?.redirectPageAfterEventCreation)
      if(createEeventData.createForInvitedUser == true){
        dispatch(setCreateForInvitedUser(false))
       /*  dispatch(setIshowInvited(false))

        dispatch(setIsInvitedUser(false))

        dispatch(emptySelectedUserData())

        dispatch(setIshowMySharedSchedule(true))
        localStorage.setItem("user_permissions","")  */
        navigate('/schedule/manage_shared_schedule')
        return

      }
      if(createEeventData.addToPersonal === true && createEeventData.addToShared === true ){
        navigate('/schedule/personal_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return
      }else if(createEeventData.addToPersonal === true){
        navigate('/schedule/personal_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return

      }else if(createEeventData.addToShared === true){
        navigate('/schedule/manage_shared_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return

      }else if(createEeventData.addToInvited === true){
        navigate('/schedule/manage_shared_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return
      }
      if(homePageCard.comeFrom == "manage_shared_schedule_screen"){

        navigate('/schedule/manage_shared_schedule')
        return
      }
      navigate('/schedule/personal_schedule')
      return

    }else if(returnPath === "user_permission_action"){
      dispatch(setIsActionAllowed(false))
      return

    } else if(location.pathname === "/schedule/edit_event"){
/* 
       if(createEeventData.addToInvited === true){
        navigate('/schedule/manage_shared_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return
      }else if(createEeventData.addToPersonal === true && createEeventData.addToShared === true ){
        navigate('/schedule/personal_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return
      }else if(createEeventData.addToPersonal === true  ){
        navigate('/schedule/personal_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return

      }else if(createEeventData.addToShared === true){
        navigate('/schedule/manage_shared_schedule')
        dispatch(setIsAddToInvited(false))
        dispatch(setIsAddToPersonal(false))
        dispatch(setIsAddToShared(false))
        return

      } */
      navigate(-1)
   /*    if(homePageCard.comeFrom === "manage_shared_schedule_screen"){
        navigate('/schedule/manage_shared_schedule')
        return
      }else{
        navigate('/schedule/personal_schedule')
      } */
    }else if(returnPath === "/login_account_reativation"){
      navigate('/schedule/personal_schedule')
      return
    } else if (returnPath === "/Umap") {
      dispatch(setOpenPinId(0))
      dispatch(setMessageModalState({isShow: false, status: false}))
      return
    } else {
      if(userManagementData.isCompnayInvitation === true){
        dispatch(setAddToSharedType(""))
        navigate("/user_administration/manage_user");

        return

      }
      if(checkSelectOption.addToSharedType === "Shared"){
        dispatch(setAddToSharedType(""))
        navigate('/schedule/manage_shared_schedule')

        return
      }

      if(checkSelectOption.addToSharedType === "Personal"){
        navigate('/schedule/personal_schedule')
        return
      }

  

      dispatch(setNoteUpdateState(false))
      dispatch(setSendToInAppFriend(false))
      dispatch(setCardItem(''))
      dispatch(setShowMessageModalForUshare(false))
      navigate('/schedule/personal_schedule')
      return
    }
  }

  return (
    <>
      <Modal show={isShow} centered style={{
        borderRadius:"10px"
      }}>
        <Modal.Body
          style={{
            background: 'rgba(17, 17, 17, 1)',
            padding: '30px',
            border: '1px solid rgba(40, 40, 40, 1)',
            borderRadius: '8px',
            boxShadow: '0px 4px 32px 0px rgba(229, 9, 20, 0.16)',
          }}
        >
          <div className='text-center mb-10'>
            <h2 className='text-white fw-bolder mb-5' style={{fontSize: '24px', fontWeight: '600'}}>
              {title}

            </h2>

            {/* <div
              className='text-500 fw-semibold fs-6 text-white  '
              style={{fontSize: '16px', fontWeight: '400'}}
            >
              Are you sure.
            </div> */}
            <div
              className='text-500 fw-semibold fs-6 text-white mb-5 '
              style={{fontSize: '16px', fontWeight: '400', whiteSpace: 'pre-line', // Add this CSS property
            }}
            >
              {message}
            </div>

            <div className='row my-12' style={{justifyContent: 'center', alignItems: 'center'}}>
              <div className='col-3'>
                <button
                  className='btn text-white col-12 mb-0'
                  style={{background: 'red'}}
                  onClick={changeMessageModalState}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {MessageModal}
