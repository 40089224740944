import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IssueInitialState {
  event_id: number
  locationLat: number
  locationlng: number
  comeFromEventDetailPage: boolean
  ShowInvitationEventDetail: boolean
  locationIconClicked: boolean
  currentPath: string
  comeFromInvitationDetail: boolean
  isGoToMap: boolean
  curentLocationLat: Number
  curentLocationLng: Number
  markerFromSelectEvent:boolean
  travelBy:string
}
const initialState: IssueInitialState = {
  event_id: -1,
  locationLat: 0,
  locationlng: 0,
  comeFromEventDetailPage: false,
  ShowInvitationEventDetail: false,
  locationIconClicked: false,
  currentPath: '',
  comeFromInvitationDetail: false,
  isGoToMap: false,
  curentLocationLat: 0,
  curentLocationLng: 0,
  markerFromSelectEvent:false,
  travelBy:""
}
const ShowEventDetail = createSlice({
  name: 'EventDetail',
  initialState,
  reducers: {
    setEventid(state, action: PayloadAction<number>) {
      state.event_id = action.payload
    },
    setLocationLatLng(state, action: PayloadAction<{lat: number; lng: number}>) {
      state.locationLat = action.payload.lat
      state.locationlng = action.payload.lng
    },
    setComeFromEventDetailPage(state, action: PayloadAction<boolean>) {
      state.comeFromEventDetailPage = action.payload
    },
    setShowInvitationEventDetail(state, action: PayloadAction<boolean>) {
      state.ShowInvitationEventDetail = action.payload
    },
    setLocationIconClicked(
      state,
      action: PayloadAction<{locationIonClicked: boolean; currentPath: string}>
    ) {
      state.locationIconClicked = action.payload.locationIonClicked
      state.currentPath = action.payload.currentPath
    },
    setComeFromInvitationDetail(state, action: PayloadAction<boolean>) {
      state.comeFromInvitationDetail = action.payload
    },
    setIsGoToMap(state, action: PayloadAction<boolean>) {
      state.isGoToMap = action.payload
    },
    setCurrentLocationLatLng(state, action: PayloadAction<{lat: Number; lng: Number}>) {
      state.curentLocationLat = action.payload.lat
      state.curentLocationLng = action.payload.lng
    },
    setComeFromSelectEvent(state, action: PayloadAction<boolean>) {
      state.markerFromSelectEvent = action.payload
    },
    setTravelBy(state, action: PayloadAction<string>){
      state.travelBy=action.payload

    }
  },
})

export default ShowEventDetail.reducer

export const {
  setEventid,
  setLocationLatLng,
  setComeFromEventDetailPage,
  setShowInvitationEventDetail,
  setLocationIconClicked,
  setComeFromInvitationDetail,
  setIsGoToMap,
  setCurrentLocationLatLng,
  setComeFromSelectEvent,
  setTravelBy

} = ShowEventDetail.actions
